<template>
  <div class="reksadana">
    <Header />
    <div class="reksadana-page">
      <b-container fluid class="reksadana-container">
        <b-row>
          <div class="col-lg-2 col-md-6 filter-collumn">
            <div class="filter-title">
              <h1><b-icon class="mr-2" icon="filter"></b-icon>Filter</h1>
            </div>
            <div class="filter-fund-type my-4">
              <h2>Jenis</h2>

              <b-form-select
                v-model="filterFundType"
                :options="fundTypeOptions"
                @change="filterType($event)"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Pilih Jenis</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>

            <div class="filter-investment-manager mb-4">
              <h2>Tanggal Jatuh Tempo</h2>
              <div class="filterDate">
                <b-form-datepicker
                  placeholder="Start Date"
                  v-model="startDate"
                  locale="en"
                ></b-form-datepicker>
                <b-form-datepicker
                  placeholder="End Date"
                  v-model="toDate"
                  locale="en"
                ></b-form-datepicker>
              </div>
            </div>
            <!-- <div class="filter-risk-profile mb-4">
              <h2>Profil Risiko</h2>
              <b-form-select
                v-model="filterRiskProfile"
                :options="riskProfileOptions"
                @change="filterRisk($event)"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Pilih Profil Risiko</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div> -->
            <!-- <div class="filter-sharia mb-4">
              <vs-checkbox id="sharia" v-model="is_sharia" name="sharia"
                >Produk Syariah
              </vs-checkbox>
            </div> -->

            <b-button block class="btn-tertiary py-2 mb-3" @click="filterClick"
              >Filter</b-button
            >
            <!-- <b-button
              block
              class="btn-show py-2 mb-4"
              :disabled="!disabled"
              @click="reloadPage"
              >Reset</b-button
            >
            <b-button block class="btn-secondary py-2 mb-3" to="/reksadana/compare">Bandingkan</b-button> -->
          </div>

          <div class="col-lg-10 col-md-6 product-collumn">
            <b-row>
              <div class="col-lg-8 col-md-6 my-auto">
                <div class="list-product-title">
                  <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                      <b-skeleton width="100%"></b-skeleton>
                    </template>
                    <h1>
                      Pilih Produk Obligasi
                      <span
                        >Performa per tanggal
                        {{ moment(navPerformanceDate).format(" DD MMM YYYY") }}
                      </span>
                    </h1>
                  </b-skeleton-wrapper>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 my-auto">
                <b-skeleton-wrapper :loading="loading">
                  <template #loading>
                    <b-skeleton width="100%"></b-skeleton>
                  </template>
                  <b-row class="justify-content-end search-form-row">
                    <div class="search-form text-right">
                      <b-form inline>
                        <div class="search-form-card">
                          <b-card>
                            <b-icon
                              class="text-muted mx-2 search-icon"
                              icon="search"
                            ></b-icon>
                            <b-form-input
                              class="my-auto"
                              v-model="filter"
                              type="search"
                              id="filterInput"
                              placeholder="Search"
                            ></b-form-input>
                          </b-card>
                        </div>
                      </b-form>
                    </div>
                  </b-row>
                </b-skeleton-wrapper>
              </div>
            </b-row>

            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <div class="my-4">
                  <b-skeleton-table
                    :rows="5"
                    :columns="8"
                    :table-props="{ bordered: true, striped: true }"
                  ></b-skeleton-table>
                </div>
              </template>
              <b-row>
                <template>
                  <div class="table-responsive table-product">
                    <b-table
                      responsive
                      id="reksadana"
                      striped
                      hover
                      outlined
                      :items="products"
                      :filter="filter"
                      :fields="fields"
                      :per-page="perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :current-page="currentPage"
                      sticky-header="80vh"
                    >
                      <template v-slot:head(navOneday)="data">
                        <div style="width: 100px">
                          <span>{{ data.label }}</span>
                        </div>
                      </template>

                      <template #cell(title)="data">
                        <div class="parentTextCommonFirst">
                          <div>
                            <input
                              type="checkbox"
                              placeholder="checked"
                              name="checked"
                              :value="data.value.id"
                              v-model="listNavCheckbox"
                            />
                            <div></div>
                          </div>
                          <div>
                            <p
                              @click="$router.push(data.item.redirect)"
                              class="textCommon"
                            >
                              {{ data.value.name }}
                            </p>
                          </div>
                        </div>
                      </template>

                      <template #cell(redirect)="data">
                        <b-button
                          :class="{
                            'btn-tertiary': true,
                            disabled: data.item.disabled,
                          }"
                          @click="
                            redirectButton(
                              data.item.redirect,
                              data.item.disabled
                            )
                          "
                        >
                          Beli
                        </b-button>
                      </template>

                      <template #cell()="data">
                        <p class="textCommon">{{ data.value }}</p>
                      </template>

                      <!-- <template v-slot:cell(actions)="data">
                        <div class="row">
                          <td style="border: none">
                            <b-button
                              @click="redirectButton(`/subscription-product-detail/${data.item.fund_code}/${data.item.id}/${data.item.fundNameFormatted}`, userSyariah && data.item.is_sharia !== 1)"
                              class="
                                btn-tertiary
                                px-4
                                py-2
                                noborder
                                inline-block
                              " :class="{ disabled: userSyariah && data.item.is_sharia !== 1 }">Beli
                            </b-button>
                          </td>
                        </div>
                      </template> -->
                    </b-table>

                    <b-row align-h="between" :style="{ width: '100%' }">
                      <b-col>
                        <b-button
                          class="btn-tertiary py-2 mb-3"
                          @click="compareClick"
                          >Bandingkan</b-button
                        >
                      </b-col>
                      <b-col>
                        <b-pagination
                          align="right"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls="table-product"
                          class="customPagination"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <!-- <div class="disclaimer">
                  <b-row class="justify-content-center mb-4">
                    <div class="col-lg-12 col-md-6">
                      <div class="card p-1">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-12 col-md-6 d-flex">
                              <div class="align-items-center">
                                <img src="@/assets/img/icons/information/information-ic.svg" alt="Disclaimer"
                                  class="mr-2" />
                              </div>
                              <p>
                                Kinerja Obligasi yang ditampilkan pada halaman ini merupakan kinerja historis masa
                                lalu. Kinerja masa lalu tidak serta-merta menjadi petunjuk untuk kinerja di masa
                                mendatang, dan
                                bukan juga merupakan perkiraan yang dibuat untuk memberikan indikasi mengenai kinerja atau
                                kecenderungannya di masa mendatang
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-row>
                </div> -->
                <small>
                  <i class="text-dark">
                    <div class="d-flex mb-5 px-3">
                      <div class="mr-2 text-nowrap">
                        <b>Peringatan :</b>
                      </div>
                      <span>
                        Investasi melalui Reksa Dana mengandung risiko dan
                        kinerja masa lalu tidak mencerminkan kinerja masa depan
                      </span>
                    </div>
                  </i>
                </small>
              </b-row>
            </b-skeleton-wrapper>
          </div>
        </b-row>
      </b-container>

      <!-- Syariah Prevent -->
      <b-modal
        v-model="modalSyariah"
        size="md"
        class="modal-failed-register"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div class="d-block text-center">
          <!-- <img
                src="@/assets/img/ekyc/verification-proccess.svg"
                alt="data-not-correct"
              /> -->
          <h1 class="p-4 my-2 text-dark">Tidak Diijinkan</h1>
          <p>Anda terdaftar sebagai nasabah Syariah</p>
          <b-row class="justify-content-md-end btn-submit-row mb-4">
            <div class="col-lg-12 col-md-6">
              <b-button
                size="sm"
                class="btn-tertiary px-4 py-2"
                @click="modalSyariah = false"
                >Tutup</b-button
              >
            </div>
          </b-row>
        </div>
      </b-modal>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Obligasi",
  metaInfo: {
    title: "Obligasi | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.fetchFunds();
    // this.fetchMi();
    this.$_loadingTimeInterval = null;
  },
  data() {
    return {
      products: [],
      product: [],
      navPerformanceDate: "",
      filterFundType: null,
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 4,
      sortBy: "navOneYear",
      sortDesc: true,
      disabled: false,
      fields: [
        {
          key: "title",
          label: "Obligasi",
          sortable: true,
        },
        {
          key: "redirect",
          label: "Beli",
        },
        {
          key: "kodeObligasi",
          label: "Kode Obligasi",
        },
        // {
        //   key: "barometer",
        //   label: "Barometer (1Th)",
        // },
        {
          key: "jenis",
          label: "Jenis",
        },
        // {
        //   key: "harga",
        //   label: "Harga",
        // },
        {
          key: "offerPrice",
          label: "Harga Beli",
        },
        {
          key: "bidPrice",
          label: "Harga Jual",
        },
        {
          key: "maturity",
          label: "Maturity Date",
        },
        {
          key: "kupon",
          label: "Kupon",
        },
      ],
      filter: "",
      perPage: 10,
      currentPage: 1,
      fundTypeOptions: [
        { value: "2", text: "Obligasi Swasta" },
        { value: "1", text: "Obligasi Negeri" },
      ],
      filterInvestmentManager: null,
      investmentManagerOptions: [],
      data_mi: [],
      filterRiskProfile: null,
      riskProfileOptions: [
        { value: "konservatif", text: "Konservatif" },
        { value: "moderat", text: "Moderat" },
        { value: "agresif", text: "Agresif" },
      ],
      filterArr: [
        { type: "type", value: null },
        { type: "mi", value: null },
        { type: "risk", value: null },
      ],
      is_sharia: false,
      product_im: [],
      product_risk: [],
      product_type: [],

      // Modal
      modalSyariah: false,
      startDate: "",
      toDate: "",
      rawProduct: [],
      listNavCheckbox: [],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    userSyariah() {
      return this.user?.individual?.client_id?.slice(0, 4).toLowerCase() ===
        "rjkl"
        ? true
        : false;
    },
    rows() {
      return this.products.length;
    },
    fundNameFormatted() {
      return this.products.fund_name.replace(/\s+/g, "-");
    },
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    redirectButton(url, disabled) {
      if (disabled) {
        this.modalSyariah = true;
      } else {
        this.$router.push(url);
      }
    },
    reloadPage() {
      window.location.reload();
    },
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    async fetchFunds() {
      this.loading = true;
      let response = await axios({
        method: "GET",
        url: "fbonds/product?bond_status=1&bond_type=corporate",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });
      let response2 = await axios({
        method: "GET",
        url: "fbonds/product?bond_status=1&bond_type=government",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });

      this.rawProduct = [...response.data.data, ...response2.data.data];

      this.mappingProduct();
      this.navPerformanceDate = new Date();
      // this.products = response.data.dataNav;
      // this.navPerformanceDate = response.data.nav_date;
    },

    async fetchMi() {
      let response = await axios({
        method: "GET",
        url: "mi",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      this.data_mi = response.data.data;

      this.data_mi.map((data) => {
        this.investmentManagerOptions.push({
          value: data.id,
          text: data.im_name,
        });
      });
    },

    filterType(event) {
      this.is_type = event.toLowerCase();
    },

    filterRisk(event) {
      this.is_risk = event.toLowerCase();
    },
    filterMi(event) {
      this.is_im = event;
    },

    async filterClick() {
      // maturity_date
      this.products = [];
      this.loading = true;
      await this.fetchFunds();
      this.rawProduct = this.rawProduct
        .filter((candidate) => {
          // Filter Type
          if (!this.filterFundType) return true;
          const val = parseInt(this.filterFundType);
          return val === candidate.bond_type;
        })
        .filter((candidate) => {
          if (!this.startDate && !this.toDate) {
            return true;
          }
          const candidateDate = moment(candidate.maturity_date);
          const startDate = moment(this.startDate);
          const toDate = moment(this.toDate);
          if (this.startDate && !this.toDate) {
            return candidateDate.isSameOrAfter(startDate);
          } else if (!this.startDate && this.toDate) {
            return candidateDate.isSameOrBefore(toDate);
          } else {
            return (
              candidateDate.isSameOrAfter(startDate) &&
              candidateDate.isSameOrBefore(toDate)
            );
          }
        });

      this.mappingProduct();
      this.disabled = true;
      this.loadingTime = await 0;
    },

    mappingProduct() {
      this.products = this.rawProduct.map((candidate) => {
        return {
          title: {
            name: candidate.bond_name,
            id: candidate.bond_id,
          },
          // disabled: this.userSyariah && candidate.is_sharia !== 1,
          redirect: `/subscription-fbonds-detail/${candidate.bond_id}/1`,
          // barometer: 2,
          kodeObligasi: candidate.bond_id,
          jenis: candidate.bond_type === 1 ? "Government" : "Corporate",
          // harga: candidate.buy_price.toFixed(),
          offerPrice: parseFloat(candidate.sell_price).toFixed(4),
          bidPrice: parseFloat(candidate.buy_price).toFixed(4),
          maturity: moment(candidate.maturity_date).format("MMM, DD YYYY"),
          kupon: candidate.coupon_rate,
        };
      });
    },

    compareClick() {
      if (this.listNavCheckbox.length > 3) {
        alert("You can only select a maximum of 3 data");
        return;
      }

      this.$router.push(
        `/obligasi/compare${
          this.listNavCheckbox.length > 0
            ? `?products=${this.listNavCheckbox.join()}`
            : ``
        }`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.filterDate {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

div.parentTextCommonFirst {
  display: flex;
  gap: 10px;
}

div.parentTextCommonFirst .textCommon {
  white-space: nowrap;
}

p.textCommon {
  color: black;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
}

p.textCommon:hover {
  opacity: 0.5;
}

.reksadana {
  background: #f3f4f6;
}

.reksadana-page {
  background: white;
}

.reksadana-container {
  padding-top: 120px;
  // padding-bottom: 60px;
  background: #f3f4f6;
}

.reksadana-product {
  background: #f3f4f6;
}

.reksadana-product-container {
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.con-vs-checkbox {
  justify-content: start;
  margin: 0;
}

.filter-collumn {
  background: white;
  padding-top: 50px;
  margin-top: -50px;
}

.filter-title {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #111827;
  }
}

.filter-fund-type {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
  }
}

.filter-investment-manager {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
  }
}

.filter-risk-profile {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
  }
}

.filter-minimum-buy {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #374151;
  }
}

.filter-sharia {
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    border-color: $secondaryColor !important;
    background-color: $secondaryColor !important;
  }

  .active {
    color: white;
    border-color: $secondaryColor !important;
    background-color: $secondaryColor !important;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
}

.product-collumn {
  padding-left: 40px;
  padding-right: 40px;
}

.card-body {
  padding: 8px;
}

.search-form {
  text-align: right;

  .form-control {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.form-control:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.list-product-title {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #111827;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b7280;
  }
}

.card-body {
  padding: 8px;
}

.list-product {
  padding-bottom: 92px;
}

.product-tabs {
  padding-top: 20px;
}

.nav-link {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  background: #f7f7f8;
  color: #4b5563;
  padding: 10px 12px 10px 12px;
  box-sizing: border-box;
  border-radius: 4px;
}

.nav-link:hover {
  background: #fcddd9;
  color: #5e1108;
}

.nav-item {
  padding: 0px 8px 0px 8px;
}

.noborder {
  border: none;
}

.table-product {
  padding-top: 16px;
  margin-left: 8px;
}

thead {
  background: #f9fafb;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #f9fafb;
}

table th {
  font-family: $inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #4b5563;
}

table a {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #5c0dab;
  text-decoration: none;
}

table td {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #111827;
}

table td p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #6b7280;
}

table {
  border: none !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

table tr th,
table tr:first-child th:first-child {
  border-top-left-radius: 0.25rem !important;
}

table tr:first-child th:last-child {
  border-top-right-radius: 0.25rem !important;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 0.25rem !important;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 0.25rem !important;
}

.borderless th,
.borderless tr {
  border: none !important;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

tr {
  border: none !important;
}

.product-image {
  border-radius: 6px;
  width: 40px;
}

.customPagination > li > a {
  color: white;
}

.customPagination > li.active > a,
.customPagination > li > a:hover {
  color: white;
  background-color: $primaryColor !important;
}

.disclaimer {
  .card {
    background: #ebf8ff;
    border: 1px solid #90cdf4;
    box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
      0px 1px 3px rgba(107, 114, 128, 0.1);
    border-radius: 6px;
  }

  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #2c5282;
    margin: 0px;
  }
}

.buy-product a {
  text-decoration: none;
}

.buy-product a:focus {
  color: white !important;
}

.buy-product a:active {
  color: white !important;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 24px !important;
  }

  h1 {
    font-size: 18px !important;
  }

  .filter-collumn {
    background: white;
    padding-top: 50px;
    margin-top: -50px;
    padding: 40px;
    margin-bottom: 50px;
  }

  .search-form-row {
    margin: 20px 20px;
    justify-content: center !important;
  }

  .search-icon {
    display: none;
  }
}

// @media only screen and (min-width: 1366px) {
//   .reksadana-container {
//     padding-bottom: 500px;
//   }
// }
</style>
